.Navbar {
    overflow: hidden;
    position: fixed;
    top: 0;
    background-color: rgba(100%, 100%, 100%) !important;
    width: 90%;
    display: inline-block;
    text-align: center;
    border-radius: 25px;
    margin-top: 0.5%;
}

.left-btns {
    float: left;
}

.right-btns {
    float: right;
    padding-right: 1%;
}

.highlight {
    color: #039772b0;
}